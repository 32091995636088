/* src/components/Loading.css */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: for a semi-transparent background */
    z-index: 9999;
  }
  