body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 /* index.css */

 .iframe-responsive {
  width: 100%;
  height: 400px;
  border: none;
  display: block;
}


/* Dark mode global styles */
body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

body.dark-mode a {
  color: #ffffff; /* Links in dark mode */
}

body.dark-mode footer {
  background-color: #222;
  color: #e0e0e0;
}

body.dark-mode footer a {
  color: #ffffff;
}

/* Default color for light mode */
.shape-svg path {
  fill: #CAD3FF; /* Light mode color */
}

/* Dark mode color change */
body.dark-mode .shape-svg path {
  fill: #FFCC00; /* Light yellow in dark mode */
}


/* Light mode styles */
.feature-item {
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
}

.feature-item .text-muted {
  color: #6c757d;
}

/* Dark mode styles */
body.dark-mode .feature-item {
  background-color: #1f1f1f;
  border: 1px solid #333;
}

body.dark-mode .feature-item .text-muted {
  color: #a0a0a0;
}

body.dark-mode h1, body.dark-mode h4, body.dark-mode p {
  color: #e0e0e0;
}

body.dark-mode .feature-item,
body.dark-mode h1,
body.dark-mode p {
    transition: background-color 0.3s, color 0.3s;
}

/* Default color (light mode) */
.text-muted {
  color: #6c757d !important;
}

/* Dark mode override */
body.dark-mode .text-muted {
  color: #dcdcdc !important; /* Lighter gray for better readability */
}

.dark-input {
  background-color: #1f1f1f !important; /* Dark background */
  color: #f5f5f5 !important; /* Light text color */
  border-color: #444 !important; /* Dark border */
}

.dark-input::placeholder {
  color: #aaa !important; /* Placeholder text color */
}
